

const qs = location.search;
const searchParams = new URLSearchParams(qs);
import {device} from './setDevice'

//系统初始化
function init() {
    //初始化cdBdmd
    if (searchParams.get("cdBdmd")) {
        localStorage.setItem("cdBdmd", searchParams.get("cdBdmd"))
    }
    //初始化cdTet
    if (searchParams.get("cdTet")) {
        localStorage.setItem("cdTet", searchParams.get("cdTet"))
    }
    //初始化device
    initSetDevices();
}

function initSetDevices() {
    const cdBdmd = localStorage.getItem("cdBdmd");
    const cdTet = localStorage.getItem("cdTet");
    console.log('cdBdmd',cdBdmd)
    device.cdBdmd = cdBdmd;
    device.cdTet = cdTet;

    //这里需要对device的登录参数做改动
    if (cdBdmd != 'system') {
        device.sdUsrTp = "11" //如果不是system则是医生登录，暂时本次需求这么处理
    }
    const deviceStr = JSON.stringify(device);
    localStorage.setItem('device', deviceStr);
}

init();
