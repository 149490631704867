

let microApps = [];

if (process.env.NODE_ENV === "development") {

    microApps = [
        {
            name: "nethis",
            entry: 'http://localhost:9521',
            container: "#appContainer1",
            prefixPath: "home/nethis",
        },
        {
            name: "hms",
            entry: 'http://localhost:8090',
            container: "#appContainer2",
            prefixPath: "home/hms",
        },
        {
            name: "work",
            entry: 'http://localhost:8092',
            container: "#appContainer3",
            prefixPath: "home/work",
        },
    ];
}

if (process.env.NODE_ENV === "production") {
    microApps = [
        {
            name: "nethis",
            entry: '/app/nethis/',
            container: "#appContainer1",
            prefixPath: "home/nethis",
        },
        {
            name: "hms",
            entry: '/app/hms/',
            container: "#appContainer2",
            prefixPath: "home/hms",
        },
        {
            name: "work",
            entry: '/app/work/',
            container: "#appContainer3",
            prefixPath: "home/work",
        },
    ];
}


export default {
    microApps
}