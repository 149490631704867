

import { loadMicroApp, initGlobalState } from "qiankun";
import CONFIG from '../micro-config'

// 缓存应用实例
const microList = new Map([])


/**
 * 获取微应用
 */
export function getMicroApp() {
    const microApps = CONFIG.microApps;
    return microApps;
}

/**
 * 加载微应用
 * @param {*} appName 微应用的名称
 * @returns 
 */
export async function doLoadMicroApp(appName) {

    return new Promise((resolve, reject) => {

        //找到该微应用
        const conf = CONFIG.microApps.find(item => item.name === appName);

        // 已缓存应用
        const cacheMicro = microList.get(conf.name);
        if (cacheMicro) {
            console.log('load已缓存微应用 success')
            resolve("load");
            return;
        }

        //是否页面存在container
        const containerId = conf.container.replace("#","");
        if (!window.document.getElementById(containerId)) {
            console.log("container不存在")
            reject("error")
            return;
        }

        // 加载未缓存应用
        const microApp = loadMicroApp({
            name: conf.name,
            entry: conf.entry,
            container: conf.container,
        });

        //缓存应用
        microList.set(conf.name, microApp)

        microApp.loadPromise.then(res=>{
            console.log('load微应用 success')
            resolve("load");
        }).catch(err=>{
            console.log('load微应用 error')
            reject("error")
        })
    })
}




