
export const device = {
  "imei": guid(),
  "model": validataOS(),
  "sysVer": getBrowser(),
  "cdApp": "nethis_pc_d_1",
  "appVer":"V:1.0.71",
  "network": getNetworkType(),
  "location": "",
  "os": validataOS(),
  "cdTet": "",
  "cdBdmd": "",
  "sdUsrTp": "52",
  "portaltp": "11"
};

//GUID
function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

//获取操作系统
function validataOS() {
  if (navigator.userAgent.indexOf("Window") > 0) {
    return "Windows";
  } else if (navigator.userAgent.indexOf("Mac OS X") > 0) {
    return "Mac OS";
  } else if (navigator.userAgent.indexOf("Linux") > 0) {
    return "Linux";
  } else {
    return "NUll";
  }
}

//获取当前浏览器及版本
function getBrowser() {
  let os = navigator.platform;
  let userAgent = navigator.userAgent;
  console.log(userAgent);
  let info = "";
  let tempArray = "";

  if (/[Ff]irefox(\/\d+\.\d+)/.test(userAgent)) {
    tempArray = /([Ff]irefox)\/(\d+\.\d+)/.exec(userAgent);
    info += tempArray[1] + tempArray[2];
  } else if (/[Tt]rident(\/\d+\.\d+)/.test(userAgent)) {
    tempArray = /([Tt]rident)\/(\d+\.\d+)/.exec(userAgent);
    if (tempArray[2] === "7.0") {
      tempArray[2] = "11";
    } else if (tempArray[2] === "6.0") {
      tempArray[2] = "10";
    } else if (tempArray[2] === "5.0") {
      tempArray[2] = "9";
    } else if (tempArray[2] === "4.0") {
      tempArray[2] = "8";
    }
    tempArray[1] = "IE";
    info += tempArray[1] + tempArray[2];
  } else if (/[Cc]hrome\/\d+/.test(userAgent)) {
    tempArray = /([Cc]hrome)\/(\d+)/.exec(userAgent);
    info += tempArray[1] + tempArray[2];
  } else if (/[Vv]ersion\/\d+\.\d+\.\d+(\.\d)* *[Ss]afari/.test(userAgent)) {
    tempArray = /[Vv]ersion\/(\d+\.\d+\.\d+)(\.\d)* *([Ss]afari)/.exec(userAgent);
    info += tempArray[3] + tempArray[1];
  } else if (/[Oo]pera.+[Vv]ersion\/\d+\.\d+/.test(userAgent)) {
    tempArray = /([Oo]pera).+[Vv]ersion\/(\d+)\.\d+/.exec(userAgent);
    info += tempArray[1] + tempArray[2];
  } else {
    info += "unknown";
  }
  return info;
}

//获取当前网络类型
function getNetworkType() {
  let ua = navigator.userAgent;
  let networkStr = ua.match(/NetType\/\w+/) ? ua.match(/NetType\/\w+/)[0] : 'NetType/other';
  networkStr = networkStr.toLowerCase().replace('nettype/', '');
  let networkType;
  switch (networkStr) {
    case 'wifi':
      networkType = 'wifi';
      break;
    case '4g':
      networkType = '4g';
      break;
    case '3g':
      networkType = '3g';
      break;
    case '3gnet':
      networkType = '3g';
      break;
    case '2g':
      networkType = '2g';
      break;
    default:
      networkType = 'WiredNetwork';
  }
  return networkType;
}
