export default {
  namespaced: true,
  state: {
    routeList: []
  },
  mutations: {
    setRouteList(state, routeList) {
      state.routeList = routeList;
    },
    pushRouteList(state, route) {
      state.routeList.push(route);
    },
    popRouteList(state, route) {
      let index = state.routeList.indexOf(route);
      if (index !== -1)
        state.routeList.splice(index, 1);
    }
  }
};
