import {
  login,
  getInfo,
  loginout
} from '@/api'

import Vue from 'vue'
import {
  ACCESS_TOKEN,
  Id_Account
} from '@/store/mutation-types'
import {
  trimStr
} from "@/utils";
import Router from 'vue-router'

Vue.use(Router)
const state = {
  token: localStorage.getItem('token'),
  showRelogin: false,
  routes: null,
  routesTree: null,
  isMenuCollapsed: false,
  multiPage: [],
  activePage: null,
};
const mutations = {
  setToken: (state, token) => {
    state.token = token;
  },
  setRoutes: (state, routes) => {
    //--------这里对路由做一个拼装--------
    const routesArr = JSON.parse(JSON.stringify(routes));

    if (Array.isArray(routesArr)) {

      //找到父节点
      function findParentPath(pid) {
        let arr = routesArr.filter((item) => {
          return item.cd === pid;
        })
        if (Array.isArray(arr) && arr.length > 0) {
          return arr[0].path;
        } else {
          return "";
        }
      }

      routesArr.forEach(item => {
        //路由中读取App的名称
        let appName = item.component;
        //这里对互联网医院做一个临时处理
        switch (appName) {
          case "hms":
            appName = "hms";
            break;
          case "work":
            appName = "work";
            break;
          default:
            appName = "nethis";
            break;
        }
        let parentPath = findParentPath(item.pid);
        let finalPath = trimStr(appName);
        let pagePath = item.path;
        let hashPath = '';
        if (parentPath) {
          finalPath += "#/" + trimStr(parentPath) + "/" + trimStr(pagePath);
          hashPath = "#/" + trimStr(parentPath) + "/" + trimStr(pagePath);
        } else {
          finalPath += "#/" + trimStr(pagePath);
          hashPath = "#/" + trimStr(pagePath);
        }
        item.routerPath = finalPath;
        item.hashPath = hashPath;
        item.appName = appName;
        if (item.meta.isOpen) {
          item.routerPath = 'app/work/#/workplace'
        }
        item.fullPath = '/home/' + finalPath;
      })
    }
    //--------这里对路由做一个拼装--------

    state.routes = routesArr;
    if (routesArr.length > 0) {
      localStorage.setItem("routes", JSON.stringify(routesArr));
    } else {
      localStorage.removeItem('routes');
    }
  },
  setRoutesTree: (state, routes) => {
    state.routesTree = JSON.parse(JSON.stringify(routes));
  },
  openRelogin: (state) => {
    state.showRelogin = true;
  },
  closeRelogin: (state) => {
    state.showRelogin = false;
  },
  changeIsMenuCollapsed: (state) => {
    state.isMenuCollapsed = !state.isMenuCollapsed;
  },
  setMultiPage(state, routerItem) {
    console.log(state.multiPage.some(v => v.name === routerItem.name))
    if (state.multiPage.some(v => v.name === routerItem.name)) {
      return
    }
    state.multiPage.push(routerItem)
  },
  setActivePage: (state, name) => {
    state.activePage = name;
  },
  deleteMultiPage(state, routerItem) {
    const tags = state.multiPage.filter(tag => tag.name !== routerItem.name);
    state.multiPage = tags
  },
}

//异步修改
const actions = {
  //用户登录
  login({
    commit
  }, userInfo) {
    return login(userInfo).then((res) => {
      commit("setToken", res);
      localStorage.setItem(Id_Account, res.idAccount);
      localStorage.setItem(ACCESS_TOKEN, res.token);
      localStorage.setItem('idPern',res.idPern)
    })
  },
  getInfo({
    state
  }) {
    return getInfo().then((res) => {
      let config = JSON.parse(res.conf);
      let menu = config.menu.data;
      return menu;
    })
  },
  //路由生成，在得到用户角色后会第一时间调用
  generateRoutes({
    commit
  }, rolesRoutes) {
    return new Promise(resolve => {
      //生成前端可用的路由
      commit('setRoutes', rolesRoutes);
      const routesPaths = JSON.parse(JSON.stringify(state.routes));
      const accessedRoutes = toTreeData(routesPaths);
      commit("setRoutesTree", accessedRoutes);
      resolve(accessedRoutes);
    })
  },
  // 添加快捷导航
  setMultiPage({
    commit
  }, routerItem) {
    commit('setMultiPage', routerItem)
  },
  //重置token
  resetToken({
    commit
  }) {
    commit("setToken", "");
    commit("setRoutes", []);
    localStorage.removeItem('token');
  },
  loginout({
    dispatch,
    commit
  }) {
    return loginout().then((res) => {
      dispatch("resetToken").then(() => { })
    })
  }
}
//后台返回的权限列表生成可用的菜单树；
export function toTreeData1(data) {
  var pos = {};
  var tree = [];
  var i = 0;
  while (data.length != 0) {
    if (data[i].pid === '0') {
      // const component = data[i].component;
      // data[i].component = resolve => {
      //   require(['@/' + component + '.vue'], resolve)
      // }
      tree.push({
        cd: data[i].cd,
        path: data[i].path,
        name: data[i].name,
        component: data[i].component,
        meta: {
          icon: data[i].meta.icon,
          isOpen: data[i].meta.isOpen
        },
        children: [],
        routerPath: data[i].routerPath
      });
      pos[data[i].cd] = [tree.length - 1];
      data.splice(i, 1);
      i--;
    } else {
      var posArr = pos[data[i].pid];
      if (posArr != undefined) {

        var obj = tree[posArr[0]];
        for (var j = 1; j < posArr.length; j++) {
          obj = obj.children[posArr[j]];
        }
        // const component = data[i].component;
        // data[i].component = resolve => {
        //   require(['@/' + component + '.vue'], resolve)
        // }
        obj.children.push({
          cd: data[i].cd,
          path: data[i].path,
          name: data[i].name,
          component: data[i].component,
          children: [],
          routerPath: data[i].routerPath
        });
        pos[data[i].cd] = posArr.concat([obj.children.length - 1]);
        data.splice(i, 1);
        i--;
      }

    }
    i++;
    if (i > data.length - 1) {
      i = 0;
    }
  }
  return tree;
}

export function toTreeData(data) {
  let tree = [];
  let levelTow = [];
  data.forEach(item => {
    if (item.pid == '0') {
      tree.push({
        cd: item.cd,
        path: item.path,
        name: item.name,
        component: item.component,
        routerPath: item.routerPath,
        appName: item.appName,
        meta: {
          icon: item.meta.icon,
          isOpen: item.meta.isOpen
        },
        children: []
      })
    } else {
      levelTow.push({
        pid: item.pid,
        cd: item.cd,
        path: item.path,
        name: item.name,
        component: item.component,
        routerPath: item.routerPath,
        appName: item.appName,
        children: []
      })
    }
  });
  levelTow.forEach(item => {
    let parentLevel = tree.find(e => {
      return item.pid == e.cd
    })
    if (!parentLevel) {
      console.log(`%c不存在pid=${item.pid}的父级`, "color:#ec1c1c");
      return;
    } else {
      parentLevel.children.push(item)
    }

  })

  return tree;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}