import request from './req';

const http = {
  get(url, params, responseType) {
    const config = {
      method: "get",
      url: url,
      responseType: responseType || '',
      headers:{
        authorization:'{"token":"' + localStorage.getItem('token') + '"}',
        device:localStorage.getItem('device')
      }
    }
    if (params) config.params = params
    return request(config)
  },
  post(url, params) {
    const config = {
      method: 'post',
      url: url,
      headers:{
        authorization:'{"token":"' + localStorage.getItem('token') + '"}',
        device:localStorage.getItem('device')
      }
    }
    if (params) config.data = params
    return request(config)
  },
  put(url, params) {
    const config = {
      method: 'put',
      url: url,
      headers:{
        authorization:'{"token":"' + localStorage.getItem('token') + '"}',
        device:localStorage.getItem('device')
      }
    }
    if (params) config.params = params
    return request(config)
  },
  delete(url, params) {
    const config = {
      method: 'delete',
      url: url,
      headers:{
        authorization:'{"token":"' + localStorage.getItem('token') + '"}',
        device:localStorage.getItem('device')
      }
    }
    if (params) config.params = params
    return request(config)
  }
}

export default http