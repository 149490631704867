import http from "./request";

export function login(params) {
    return http.post("/basedata/usercenter/login", params);
}
export function getInfo() {
    let params = {
        confVer: "100",
        signApp: "web.manage",
        signAppModules: "menu",
        md5: ""
    }
    return http.get('/basedata/appconf/app/conf/detail', params)
}
export function loginout() {
    return http.get("/basedata/usercenter/logout");
}
export function getyzm(params) {
    return http.get("/basedata/usercenter/comm/sms/query", params);
}
export function getImgCode(params) {
    return http.get("/basedata/usercenter/comm/imgcode/query", params, 'arraybuffer');
}