import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'
import keepAliveConf from './modules/keepAliveConf';

Vue.use(Vuex);
const store = new Vuex.Store({
    getters: {
        hasRouters: state => state.user.routes
    },
    modules: {
        user,
        keepAliveConf
    }
})

export default store