import {
  router
} from './router'
import {
  ACCESS_TOKEN
} from '@/store/mutation-types'
import store from '@/store'
import { getMicroApp, doLoadMicroApp } from "./core/MicroApp";

const allowList = ['login'] // no redirect allowList
const loginRoutePath = '/login'
const defaultRoutePath = '/home/'

router.beforeEach(async (to, from, next) => {

  //------------乾坤路由的拦截------------
  const microApps = getMicroApp();
  //存在微应用
  const conf = microApps.find(item => to.path.indexOf(item.name) !== -1);
  if (conf) {
    try {
      await doLoadMicroApp(conf.name);
    } catch (error) {

    }
  }
  //------------乾坤路由的拦截-------------

  /* has token */
  if (localStorage.getItem(ACCESS_TOKEN)) {
    if (to.path === loginRoutePath) {
      next({
        path: defaultRoutePath
      })
    } else {
      if (store.getters.hasRouters) {
        next();
      } else {
        let routes = [];
        // 从服务器取子应用路由
        try {
          const userInfo = await store.dispatch('user/getInfo')
          routes = userInfo;
          await store.dispatch('user/generateRoutes', routes)
        } catch (error) {

        }
        next()
      }

    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({
        path: loginRoutePath
      })
    }
  }
})

router.afterEach(() => {

})