import Vue from 'vue'
import App from './App.vue'
import {
  router
} from './router'
import Antd from 'ant-design-vue'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import './permission' // permission control
import './common/init'
import CONFIG from './micro-config';
import { prefetchApps } from 'qiankun';

Vue.config.productionTip = false
Vue.use(Antd);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#uniApp')


//加载微应用
const doPrefetchApps = () => {
  const apps = [];
  const microApps = CONFIG.microApps;
  microApps.forEach(item => {
    apps.push({
      name: item.name,
      entry: item.entry
    })
  })

  prefetchApps(apps);
}

doPrefetchApps();


