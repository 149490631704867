

export function getBaseUrl(){
  let baseURL = ""
  if(process.env.NODE_ENV === "development"){
    baseURL = ''
  }
  if(process.env.NODE_ENV === "production"){
    baseURL = ''
    //baseURL = "https://srv.gaoxinhealth.com"
  }
  return baseURL;
}


export function getCdBdmd(){
  let cdBdmd = "";
  if(localStorage.getItem(cdBdmd)){
    cdBdmd = localStorage.getItem(cdBdmd);
  }
  return cdBdmd;
}

export function getCdTet(){
  let cdTet = "";
  if(localStorage.getItem(cdTet)){
    cdTet = localStorage.getItem(cdTet);
  }
  return cdTet;
}

export function getUserProteCD(){
  let userProteCD = "A003,A004";
  const cdBdmd = getCdBdmd();
  if(cdBdmd === 'system'){
    //如果是系统管理员则登录参数做更改
    userProteCD = "A005,A006"
  }
  return userProteCD;
}



