
import VueRouter from 'vue-router'

export const constantRouterMap = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/home/*',
        component: () => import('@/pages/home/index.vue'),
        name: 'home',
    },
    {
        path: '/login',
        component: () => import('@/pages/login/index.vue'),
        name: 'login',
    },
]

export let router = new VueRouter({
    //base:baseUrl,
    mode: 'history',
    routes: constantRouterMap
})

