import axios from 'axios';
import {
  message
} from 'ant-design-vue';
import {getBaseUrl} from '../../common/commonGlobal'
import {
  router
} from '@/router'
import {
  ACCESS_TOKEN,
} from '@/store/mutation-types'

// 请求取消
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
//地址，随后根据环境修改
const baseurl = getBaseUrl();

const config = {
  baseURL: baseurl, // 公共地址
  timeout: 3000, // 超时
  cancelToken: source.token, // 取消请求（比如你进入某个路由后，可能就停在了这个页面就1s时间， 就立马进入了下个路由，这时，前边这个路由的请求就可以取消掉了）
}
const request = axios.create(
  config
);


//添加请求拦截器
request.interceptors.request.use((config) => {
  return config;
}, (error) => {
  message.error(error.message);
  return Promise.reject(error)
});

//添加相应拦截器
request.interceptors.response.use((response) => {
  //没有header，视为流，直接返回
  if (!response.data.header) {
    let xx = btoa(
      new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
    )
    return ("data:image/png;base64," + xx)
  }
  if (response.data.header.errcode === "0000000000") {
    return response.data.data;
  } else {
    if (response.data.header.errcode === "0000000415" && !location.pathname.includes('login')) {
      localStorage.removeItem(ACCESS_TOKEN)
      router.push({
        name: 'login'
      })
    }
    const errmsg = `${response.data.header.errmsg}`;
    message.error(errmsg)
    return Promise.reject(errmsg);
  }
}, (error) => {
  //响应错误的处理
  if (error && error.response) {
    //1.公共错误处理
    //2.根据响应码具体处理
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break;
      case 401:
        error.message = '未授权，请重新登录'
        break;
      case 403:
        error.message = '拒绝访问'
        break;
      case 404:
        error.message = '请求错误,未找到该资源'
        // window.location.href = "/NotFound"
        break;
      case 405:
        error.message = '请求方法未允许'
        break;
      case 408:
        error.message = '请求超时'
        break;
      case 500:
        error.message = '服务器端出错'
        break;
      case 501:
        error.message = '网络未实现'
        break;
      case 502:
        error.message = '网络错误'
        break;
      case 503:
        error.message = '服务不可用'
        break;
      case 504:
        error.message = '网络超时'
        break;
      case 505:
        error.message = 'http版本不支持该请求'
        break;
      default:
        error.message = `连接错误${error.response.status}`
    }
  } else {
    // 超时处理
    error.message = '连接服务器失败'
    if (JSON.stringify(error).includes('timeout')) {
      error.message = '服务器响应超时，请刷新当前页'
    }
  }
  message.error(error.message)
  return Promise.reject(error);
})

export default request